export class AclAccessModel {
    username: string;
    name: string;
    pollAccess: boolean;
    eventAccess: boolean;
    challengeAccess: boolean;
    activityAccess: boolean;
    _id: string;

    constructor(data) {
        this.username = data.username;
        this.name = `${data.firstName ? data.firstName : ''} ${data.lastName ? data.lastName : ''}`;
        this._id = data._id;
        this.pollAccess = data.pollAccess;
        this.eventAccess = data.eventAccess;
        this.challengeAccess = data.challengeAccess ? data.challengeAccess : false;
        this.activityAccess = data.activityAccess ? data.activityAccess : false;
    }

}
