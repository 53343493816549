import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SharedServiceService {

    constructor() {
    }


    getUtcDateOrTime(date, type: DATE_CONVERTER_TYPE) {
        let finalTime;
        if (type == DATE_CONVERTER_TYPE.startDate) {
            const newDate: Date = new Date(date);
            newDate.setHours(0, 0, 0);
            const time = newDate.getTime();
            finalTime = time + (Math.abs(newDate.getTimezoneOffset() * 60000));
        }

        if (type == DATE_CONVERTER_TYPE.endDate) {
            const newDate: Date = new Date(date);
            newDate.setHours(23, 59, 59);
            const time = newDate.getTime();
            finalTime = time + (Math.abs(newDate.getTimezoneOffset() * 60000));
        }
        if (type == DATE_CONVERTER_TYPE.dateTime) {
            const newDate: Date = new Date(date);
            const time = newDate.getTime();
            finalTime = time + (Math.abs(newDate.getTimezoneOffset() * 60000));
        }
        return new Date(finalTime).getTime();
    }

    getLocalDateFromUTC(UTC_Date) {
        const dd: Date = new Date(UTC_Date);
        return new Date(dd.getUTCFullYear(), dd.getUTCMonth(), dd.getUTCDate(), dd.getUTCHours(), dd.getUTCMinutes(), dd.getUTCSeconds());
    }
}


export enum DATE_CONVERTER_TYPE {
    startDate = 'START_DATE',
    endDate = 'END_DATE',
    dateTime = 'DATE_TIME',
}
